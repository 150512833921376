import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkService from "../../network/NetworkService";
import { CHECKBOX, RADIO } from "../../constants/api-constants";

export const startAssessmentThunk = createAsyncThunk(
    '/start-assessment',
    async (body: any, thunkApi) => await NetworkService.apiStartAssessment(body)
)

interface Choice {
    achId: number;
    optionAnswers: string;
    isSelected: number;
}

interface Question {
    questionId: number;
    imageURL: string;
    question: string;
    componentTypeId: number;
    choiceList: Choice[];
}

interface Category {
    catId: number;
    catDescription: string;
    noOfQns: string;
    questionList: Question[];
}

const initialAssessment = {
    allowedTimeSec: 0,
    categoryList: [] as Category[],
}

const assessmentMasterSlice = createSlice({
    name: "assessmentMaster",
    initialState: {
        assessment: { ...initialAssessment },
        isLoading: true,
        errorMessage: '',
        currentCategory: 0,
        initTimmer: 0,
    },
    reducers: {
        categoryStepNext: (state) => {
            return {
                ...state,
                currentCategory: state.currentCategory + 1
            }
        },
        updateTimeCount: (state, action) => {
            return {
                ...state,
                initTimmer: action.payload
            }
        },
        updateChoice: (state, action) => {
            const { catId, questionId, achId } = action.payload;

            return {
                ...state,
                assessment: {
                    ...state.assessment,
                    categoryList: state.assessment.categoryList.map(category => {
                        if (category.catId !== catId) return category;
                        return {
                            ...category,
                            questionList: category.questionList.map(question => {
                                if (question.questionId !== questionId) return question;
                                return {
                                    ...question,
                                    choiceList: question.choiceList.map(choice => {
                                        if(question.componentTypeId === CHECKBOX) {
                                            if (choice.achId !== achId) {
                                                return choice
                                            } else {
                                                return {
                                                    ...choice,
                                                    isSelected: choice.isSelected ? 0 : 1
                                                }
                                            }
                                        } else if(question.componentTypeId === RADIO) {
                                            if (choice.achId !== achId) {
                                                return {
                                                    ...choice,
                                                    isSelected: 0
                                                }
                                            } else {
                                                return {
                                                    ...choice,
                                                    isSelected: choice.isSelected ? 0 : 1
                                                }
                                            }
                                        }

                                        return choice;
                                    }),
                                };
                            }),
                        };
                    }),
                }
            };
        },         
    },
    extraReducers: (builder) => {
        builder
            .addCase(startAssessmentThunk.pending, (state, action) => {
                state.isLoading = true
                state.errorMessage = ''
            })
            .addCase(startAssessmentThunk.fulfilled, (state, action) => {
                const { data } = action.payload as any;

                state.assessment = data
                state.isLoading = false
                state.errorMessage = ''
            })
            .addCase(startAssessmentThunk.rejected, (state, action) => {
                state.errorMessage = action.error.message ?? ''
                state.isLoading = false
            })
    }
});

export const { categoryStepNext, updateTimeCount, updateChoice } = assessmentMasterSlice.actions
export default assessmentMasterSlice.reducer;