import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState, store } from '../../redux/store';
import { initAssessmentThunk, verifyOtpThunk } from '../../redux/slice/initAssessmentSlice';
import { useSnackbar } from '../../context/SnackbarContext';
import SecureStorage from '../../util/SecureStorage';
import { LSK_OTP, LSK_UID } from '../../constants/local-storage-constants';
import { startAssessmentThunk } from '../../redux/slice/assessmentMasterSlice';
import { ASSESSMENT } from '../../routes/routes';
import { useNavigate } from 'react-router-dom';
import LOGO from '../../assets/styra_logo.png';
import { Button } from '@mui/material';

const OtpVerification = () => {
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar()
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const firstInputRef = useRef<HTMLInputElement>(null);

    const { isLoading, errorMessage, userData, successMessage } = useSelector((state: RootState) => state.initAssessment);

    const handleChange = (element: any, index: number) => {
        const value = element.value;

        if (!isNaN(value)) {
            const updatedOtp = [...otp];
            updatedOtp[index] = value;
            setOtp(updatedOtp);

            // Focus next input
            if (element.nextSibling) {
                element.nextSibling.focus();
            }
        }
    }

    const dispatch: AppDispatch = useDispatch()

    const handleSubmit = () => {
        const uid = SecureStorage.getItem(LSK_UID);
        const params = {
            uid: uid,
            otp: otp.join(''),
        };
        dispatch(verifyOtpThunk(params))
        .unwrap()
        .then((res) => {
            SecureStorage.setItem(LSK_OTP, otp.join(''));
            // showSnackbar(successMessage);

            dispatch(startAssessmentThunk(params));
            navigate(ASSESSMENT, { 
                replace: true,
            });
        })
        .catch((e) => {
            const errMsg = store.getState().initAssessment.errorMessage;
            showSnackbar(errMsg);
        });
    }

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, []);

  return (
    <div className="vh-100 bg-light">
        <div className="row justify-content-center g-0 p-0 py-lg-3 m-0 h-100">
            <div className="col-lg-6 rounded bg-white p-5 h-100">
                <img src={LOGO} alt='...' style={{height: '50px'}} />

                <div className="d-flex gap-4 justify-content-center align-items-center flex-column h-100">
                    <span className="fs-med-lg-semibold text-black text-center">
                        Hi, <span className="text-uppercase">{userData.fullName}</span>
                    </span>

                    <div className="d-flex flex-column">
                        <span className="fs-med-slg-medium text-black text-center">Verification</span>
                        <span className="fs-normal-light text-center text-grey">Enter your OTP</span>
                    </div>

                    <div className="d-flex gap-1 align-items-center">
                        {otp.map((item, index) => (
                            <input
                                className="otp-box text-center py-3"
                                type="text"
                                name="otp"
                                maxLength={1}
                                key={index}
                                value={item}
                                onChange={(e) => handleChange(e.target, index)}
                                onFocus={(e) => e.target.select()}
                                ref={index === 0 ? firstInputRef : null}
                            />
                        ))}
                    </div>

                    <div className="d-flex gap-2 align-items-center">
                        <Button
                            sx={{textTransform: 'none'}}
                            variant="contained"
                            onClick={handleSubmit}
                            disableElevation
                        >
                            <span>Proceed</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default OtpVerification;